import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import Dashboard from './pages/Dashboard';
import { AuthProvider } from './provider/AuthProvider';

const GlobalLayout = React.lazy(() => import ('./layouts/GlobalLayout'));

const Wrapper = styled.div`
  background-image: url('/global_background.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
`

function App() {
  return (
    <AuthProvider>
      <Wrapper>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<GlobalLayout />} />
          </Routes>
        </BrowserRouter>
      </Wrapper>
    </AuthProvider>
  );
}

export default App;
